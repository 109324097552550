import { onBeforeUnmount } from 'vue'

export const useTenantPrivateChannel = (
    channel,
    options = { leaveOnUnmount: true }
) => {
    const { tenantId } = useAuth()
    if (!tenantId) {
        throw Error('Do not have tenant')
    }

    return usePrivateChannel(`${channel}.${tenantId.value}`, options)
}

export const usePrivateChannel = (
    channel,
    { leaveOnUnmount } = { leaveOnUnmount: true }
) => {
    if (!window.Echo) {
        throw Error('window.Echo has not been initialized')
    }

    const echo = computed(() => window.Echo.private(channel))

    if (leaveOnUnmount) {
        onBeforeUnmount(() => {
            window.Echo.leaveChannel(channel)
        })
    }

    return { echo }
}

export const useTenantListen = (
    channel,
    event,
    callback = null,
    options = { leaveOnUnmount: true }
) => {
    const emitter = useEmitter()
    const { echo } = useTenantPrivateChannel(channel, options)

    onMounted(() => {
        echo.value.listen(event, (...args) => {
            emitter.emit(`echo-${event}`, ...args)
            callback && callback(...args)
        })
    })

    if (options.leaveOnUnmount) {
        onUnmounted(() => {
            echo.value.stopListening(event)
        })
    }
}

export const useListen = (channel, event, callback = null) => {
    const emitter = useEmitter()
    const { echo } = usePrivateChannel(channel)

    onMounted(() => {
        echo.value.listen(event, (...args) => {
            emitter.emit(`echo-${event}`, ...args)
            callback && callback(...args)
        })
    })

    onUnmounted(() => {
        echo.value.stopListening(event)
    })
}

export const useListenForWhisper = (channel, event, callback = null) => {
    const emitter = useEmitter()
    const { echo } = usePrivateChannel(channel)

    onMounted(() => {
        echo.value.listenForWhisper(event, (...args) => {
            emitter.emit(`echo-${event}`, ...args)
            callback && callback(...args)
        })
    })

    onUnmounted(() => {
        echo.value.stopListeningForWhisper(event)
    })
}

export const useListenNotification = (channel, callback = null) => {
    const emitter = useEmitter()
    const { echo } = usePrivateChannel(channel)

    onMounted(() => {
        echo.value.notification((...args) => {
            emitter.emit(`echo-notification`, ...args)
            callback && callback(...args)
        })
    })

    onUnmounted(() => {
        echo.value.stopListening(
            '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
        )
    })
}

export const useListenEmitEcho = (event, callback) => {
    const emitter = useEmitter()

    onMounted(() => {
        emitter.on(`echo-${event}`, callback)
    })

    onUnmounted(() => {
        emitter.off(`echo-${event}`, callback)
    })
}
